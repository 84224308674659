<template>
	<el-row>
		    <el-carousel height="464px">
		      <el-carousel-item>
		        		<banner p="人工坐席 永久免费" con="无时间和坐席限制，全渠道打通，支持所有线上推广平台" url="url('/banner/1.png')" />
		      </el-carousel-item>
			  <el-carousel-item>
			    	<banner p="在线客服" con="最快连接你与客户，无需下载部署，注册即用，最快搭建与客户无障碍交流的桥梁" url="url('/banner/2.png')"/>
			    	
			  </el-carousel-item>
			  <el-carousel-item>
			    	<banner p="智能AI客服机器人" con="7*24小时全渠道在线与客户建立联系，极速响应客户问题，替代人工客服解决绝大部分咨询问题，帮助企业提升客服体验，提升坐席服务效率，降低人力成本"
			    		url="url('/banner/3.png')" />
			  </el-carousel-item>
	
		    </el-carousel>
		

		<el-row class="gntd">
			<div class="tl-con">
				<p>功能特点</p>
				<div class="pl">多渠道集成，快速与客户沟通对话，通过在线聊天、即时提醒、数据分析、智能机器人等功能，帮助企业提升客户访问体验，促进成单转化</div>
			</div>

			<div class="cardlist">

				<div class="item">
					<div><img src="@/assets/svg/sy/1.svg" alt="" srcset="">全渠道一键集成</div>
					<div>聚合所有主流营销渠道，客户管理一站式解决，多种灵活辅助功能，提供快捷工作方式高效客服工作台。</div>
				</div>
				<div class="item">
					<div><img src="@/assets/svg/sy/2.svg" alt="" srcset="">多入口接入，适应性强</div>
					<div>支持移动网站、桌面网站、移动应用SDK等接入方式，支持所有手机浏览器，并且对频繁切换的网络环境有极好的适应性。</div>
				</div>
				<div class="item">
					<div><img src="@/assets/svg/sy/3.svg" alt="" srcset="">更全面的数据分析</div>
					<div>利用网页插件统计网站的访问量信息、访客数量、访问次数、浏览量等。将不同等级的对话，进行多维度交叉分析。</div>
				</div>
				<div class="item">
					<div><img src="@/assets/yxzn/sss.png" alt="" srcset="">网站样式自定义</div>
					<div>网站插件和移动应用 SDK 支持丰富的外观和功能自定义，只需要点点鼠标，即可修改网站插件的颜色、样式和各种功能。</div>
				</div>

			</div>
		</el-row>

		<el-row class="cpys">
			<div class="tl-con">
				<p @click="s=!s">产品优势</p>
				<div class="pl">打破传统客服痛点，为企业带来全新的客户服务新模式，节省人力成本，大幅提升人工客服效率</div>
			</div>
			<transition name="el-fade-in-linear">
				<div class="gd-card" v-if="s">

					<div class="l">
						<div class="_title">业务场景</div>
						<p>学习优势</p>
						<p>专业技能</p>
						<p>客户服务</p>
						<p>及时响应</p>
						<p>智能质检</p>
						<p>业务管理</p>
						<p>服务成本</p>
					</div>

					<div class="m">
						<div class="_title">AI客服</div>
						<p>智能匹配、随时上岗</p>
						<p>海量级话术一键导入 话术统一不流失</p>
						<p>全年无休、7*24小时</p>
						<p>无上限沟通 多进度处理</p>
						<p>程序稳定 永保最佳</p>
						<p>随时开随时用、易管理</p>
						<p>一次付费、常年使用</p>
					</div>


					<div class="r">
						<div class="_title">人工客服</div>
						<p>培养周期长</p>
						<p>业务水平参差不齐</p>
						<p>工作时长有限</p>
						<p>无法多人沟通</p>
						<p>容易情绪化、状态不稳定</p>
						<p>难管理、容易流失</p>
						<p>用人成本较高</p>

					</div>

				</div>
			</transition>
		</el-row>

		<el-row class="znhyx">

			<div class="tl-con">
				<p>智能化营销，掌握核心数据</p>
				<div class="pl">为企业提供精准营销分析，全方位分析数据为提升服务质量效率，帮助企业加速智能化升级</div>
			</div>

			<div class="tap">
				<div :style="{color:idx==0?'#3A87FC':''}" @click="idx=0">

					<img src="@/assets/svg/sy/5.svg" v-if="idx==0" />
					<img v-else src="@/assets/qht/xwgl-off.png" />
					掌握客户行为规律
				</div>
				<el-divider direction="vertical"></el-divider>
				<div :style="{color:idx==1?'#3A87FC':''}" @click="idx=1">
					<img src="@/assets/qht/sjfx-on.png" v-if="idx==1" />
					<img v-else src="@/assets/svg/sy/6.svg" />
					数据分析精准营销
				</div>
				<el-divider direction="vertical"></el-divider>
				<div :style="{color:idx==2?'#3A87FC':''}" @click="idx=2">
					<img src="@/assets/qht/znfp-on.png" v-if="idx==2" />
					<img v-else src="@/assets/svg/sy/7.svg" />
					智能分配用户
				</div>
				<el-divider direction="vertical"></el-divider>
				<div :style="{color:idx==3?'#3A87FC':''}" @click="idx=3">
					<img src="@/assets/qht/sjjm-on.png" v-if="idx==3" />
					<img v-else src="@/assets/svg/sy/8.svg" />
					多重数据加密
				</div>
			</div>
			<div class="con" v-show="idx==0">
				<div class="l">
					<p>全面分析用户浏览规律，掌握访客需求</p>
					<div>客服人员可全程监控网站用户数据，对来源跟踪、身份自动识别、活动轨迹等数据进行收集、整理、统计，对不同类型的访客进行360度的了解，进行精准营销。 </div>
					<div class="kkz">
						<span>竞争分析</span>
						<span>访客需求</span>
						<span>用户数据</span>
					</div>
					<el-button type="primary" @click="tg('https://guest.51xd.cn/embed/link.html?mch_token=5272dc26_b3a4_4b12_924d_fb9e65f9061b')">了解详情</el-button>
				</div>

				<div class="r"><img src="@/assets/yxzn/1.png" /></div>
			</div>

			<div class="con" v-show="idx==1">
				<div class="l">
					<p>58+项数据报表多维度分析营销，为企业提供精准营销分析</p>
					<div>
						提供客服对话、服务质量、对话效率、对话关闭等数十项客服业务指标，为提升服务质量效率提供了数据化依据，能全方位分析市场广告投放、深究消费者行为、为企业打造全方位的广告数据分析，让企业快速了解市场竞争环境，从而制定扬长避短的广告策略。
					</div>
					<div class="kkz">
						<span>精准营销</span>
						<span>多维度</span>
						<span>广告数据</span>
					</div>
					<el-button type="primary" @click="tg('https://guest.51xd.cn/embed/link.html?mch_token=5272dc26_b3a4_4b12_924d_fb9e65f9061b')">了解详情</el-button>
				</div>

				<div class="r"><img src="@/assets/yxzn/2.png" /></div>
			</div>

			<div class="con" v-show="idx==2">
				<div class="l">
					<p>智能识别用户优先级，合理分配客服，提高转化率</p>
					<div>1.根据会员等级、来源渠道、需求类别、关键行为、所处业务节点等对用户进行分类。
						2.根据分工、响应时长、用户满意度、转化率、工作负荷、在线状态等对客服进行分类。
						3.根据熟客分配、优先分配、自动分配、技能分配等10种分配方式灵活组合。</div>
					<div class="kkz">
						<span>智能识别</span>
						<span>转化率</span>
						<span>合理分配</span>
					</div>
					<el-button type="primary" @click="tg('https://guest.51xd.cn/embed/link.html?mch_token=5272dc26_b3a4_4b12_924d_fb9e65f9061b')">了解详情</el-button>
				</div>

				<div class="r"><img src="@/assets/yxzn/3.png" /></div>
			</div>

			<div class="con" v-show="idx==3">
				<div class="l">
					<p>多重数据加密让企业与网站访客的对话更安全</p>
					<div>1.数据加密、动态口令多重保障，防御抵挡外来攻击。
						2.数据库完全隔离，3副本备份+数据快照，自动宕机迁移，自动快照备份。
						3.DDOS攻击防护，安全组防火墙，木马查杀，防暴力破解漏洞热修复，数据独立加密。</div>
					<div class="kkz">
						<span>数据加密</span>
						<span>自动备份</span>
						<span>木马查杀</span>
					</div>
					<el-button type="primary" @click="tg('https://guest.51xd.cn/embed/link.html?mch_token=5272dc26_b3a4_4b12_924d_fb9e65f9061b')">了解详情</el-button>
				</div>

				<div class="r"><img src="@/assets/yxzn/4.png" /></div>
			</div>






		</el-row>
		<el-row class="wmys">
			<div class="tl-con">
				<p>我们的优秀，不止于此</p>
				<div class="pl">专注解决企业和顾客之间连接的问题。我们非常注重软件的品质和使用体验，更注重其为客户带来的价值。为了这个目标，每一天都在持续改进。</div>
			</div>
			<transition name="el-fade-in">
				<div class="con" v-if="l">



					<div class="item">
						<img src="@/assets/wmyx/8.png" />
						<p>快速开通</p>
						<div>30秒快速注册，可完全自助开通使用，登录账户即可使用。</div>
					</div>


					<div class="item">
						<img src="@/assets/wmyx/10.png" />
						<p>移动端友好</p>
						<div>0 秒打开，没有跳转、等待和流失；网络自动重连、多浏览器兼容。</div>
					</div>

					<div class="item">
						<img src="@/assets/wmyx/12.png" />
						<p>智能机器人</p>
						<div>依托情感计算、自然语言处理、深度学习、知识工程、文本处理等人工智能技术，模拟人工对话</div>
					</div>

					<div class="item">
						<img src="@/assets/wmyx/9.png" />
						<p>系统报表</p>
						<div>完善的报表系统，大数据工具处理，可多维度筛选的动态报表。</div>
					</div>

					<div class="item">
						<img src="@/assets/wmyx/11.png" />
						<p>智能化营销</p>
						<div>依托AI智能接待，帮助中小企业进行数字化智能化营销转型，提升工作效率，降低人力成本及风险。</div>
					</div>

					<div class="item">
						<img src="@/assets/wmyx/7.png" />
						<p>客服管理</p>
						<div>多种对话分配规则，报表多维度筛选，客服管理工作指标一目了然。</div>
					</div>

					<div class="item">
						<img src="@/assets/wmyx/6.png" />
						<p>精细化运营</p>
						<div>通过来源、访客分析、全天候获客、人机协同，智能提取线索帮助企业针对性投放，挖掘更多客户价值。</div>
					</div>

					<div class="item">
						<img src="@/assets/wmyx/5.png" />
						<p>安全性</p>
						<div>银行级 HTTPS（SSL）加密，拒绝窃听和破解。 7*24 系统漏洞扫描预警。</div>
					</div>

				</div>
			</transition>
		</el-row>
		<el-row class="hzhb">
			<div class="tl-con">
				<p>您所在的行业都在选择我们</p>
			</div>
			<div class="con">
				<img :src="'/hzhb/'+item+'.png'" v-for="item in 24" :key="item" />
			</div>

		</el-row>



		<footer-s />
	</el-row>
</template>

<script>
	export default {
		props: ['initTop'],

		watch: {
			initTop: function(val) {
				console.log(val)
				if (val > 633) {
					this.s = true
				}
				if (val > 1800) {
					this.l = true
				}

			}
		},
		data() {
			return {
				s: false,
				l: false,
				idx: 0
			}
		},

		mounted() {
		
			setInterval(()=>{
				if(this.idx>2){
					
					this.idx=0
				}else{
					this.idx++
				}
				
			},5000)
		}
	}
</script>

<style scoped lang="scss">
	.hzhb {
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 55px;
		padding-bottom: 60px;

		.con {
			display: flex;
			flex-wrap: wrap;
			margin-top: 40px;

			img {
				cursor: pointer;
				border-radius: 5px;
				width: 186px;
				height: 88px;
				margin-right: 16px;
				margin-bottom: 16px;

				&:nth-child(6n) {
					margin-right: 0;
				}

				&:hover {
					box-shadow: 0px 2px 15px 7px rgba(76, 126, 233, 0.1);
				}
			}
		}
	}

	.znhyx {
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 88px;
		padding-bottom: 96px;

		.con {
			display: flex;
			margin-top: 52px;
			padding-left: 40px;
			box-sizing: border-box;

			.kkz {
				span {
					width: 80px;
					height: 32px;
					line-height: 32px;
					border: 1px solid #999999;
					border-radius: 4px;
					text-align: center;
					margin-right: 16px;
					display: inline-block;
					margin-bottom: 24px;
					font-weight: 400;
					font-size: 14px;
					color: #60666F;
				}
			}

			.l {
				margin-right: 80px;

				p {
					font-weight: 500;
					font-size: 32px;
					line-height: 48px;
					color: #182F50;
					margin-bottom: 22px;

					&+div {
						font-weight: 400;
						font-size: 14px;
						line-height: 22px;
						color: #60666F;
						margin-bottom: 85px;
					}
				}
			}
		}

		.tap {
			>div {
				cursor: pointer;
				display: flex;
				align-items: center;

				img {
					margin-right: 8px;
				}
			}

			margin-top: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #60666F;

			.el-divider--vertical {
				width: 2px;
				margin: 0 32px;
			}
		}
	}

	.gd-card {
		display: flex;
		max-width: 985px;
		margin: 0 auto;
		text-align: center;
		position: relative;
		padding-top: 40px;

		._title {
			font-weight: 500;
			font-size: 24px;
			line-height: 36px;
			color: #FFFFFF;

			&~p {
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				color: #FFFFFF;
				margin-top: 24px;
			}
		}

		.l {
			padding: 63px 0px;
			box-sizing: border-box;
			width: 200px;
			height: 498px;
			background: #3A87FC;
			border-radius: 20px;
			position: absolute;
			z-index: 888;
		}

		.m {
			box-sizing: border-box;
			padding: 43px 0;
			width: 418px;
			height: 458px;
			background: #3E4959;
			border-radius: 20px;
			position: absolute;
			left: 180px;
			top: 60px;
			z-index: 777;
		}

		.r {
			left: 400px;
			box-sizing: border-box;
			padding: 23px 0;
			width: 418px;
			height: 418px;
			background: #F5F7FA;
			border-radius: 20px;
			box-shadow: 0px 2px 15px 7px rgba(76, 126, 233, 0.1);
			position: absolute;
			z-index: 666;
			left: 580px;
			top: 80px;

			._title,
			p {
				color: #182F50;
			}

		}

	}

	.cpys,
	.wmys,
		{
		padding-top: 88px;
		padding-bottom: 96px;
		background: #F5F5F5;

		.con {
			display: flex;
			max-width: 1200px;
			margin: 0 auto;
			margin-top: 40px;
			flex-wrap: wrap;

			.item {
				width: 288px;
				height: 230px;
				background: #FFFFFF;
				box-shadow: 0px 1px 8px 5px rgba(76, 126, 233, 0.1);
				border-radius: 10px;
				text-align: center;
				padding: 0 22px;
				box-sizing: border-box;
				margin-right: 16px;
				margin-bottom: 16px;

				&:hover {
					box-shadow: 0px 2px 15px 7px rgba(76, 126, 233, 0.2);
				}

				&:nth-child(4n) {
					margin-right: 0;
				}

				img {
					margin-top: 32px;
				}

				p {
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #182F50;

					&+div {
						font-weight: 400;
						font-size: 14px;
						line-height: 22px;
						text-align: center;
						color: #60666F;
					}
				}
			}
		}
	}


	.cpys {
		height: 807px;
		padding-bottom: 0;
		box-sizing: border-box;
	}

	.tl-con {
		text-align: center;

		p {
			font-weight: 500;
			font-size: 32px;
			line-height: 48px;
			color: #182F50;
		}

		.pl {
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #60666F;
			margin-top: 9px;
		}
	}

	.gntd {
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 88px;
		padding-bottom: 96px;

		.cardlist {
			display: flex;
			margin-top: 40px;

			.item {
				background: #FFFFFF;
				box-shadow: 0px 1px 8px 5px rgba(76, 126, 233, 0.1);
				border-radius: 10px;
				width: 287px;
				height: 150px;
				margin-right: 19px;
				padding: 24px 16px;
				box-sizing: border-box;

				&:hover {
					box-shadow: 0px 2px 15px 7px rgba(76, 126, 233, 0.2);
				}

				&:last-child {
					margin-right: 0px;
				}

				div {
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #182F50;
					display: flex;
					align-items: center;

					img {
						margin-right: 8px;
					}

					&:last-child {
						font-weight: 400;
						font-size: 14px;
						line-height: 22px;
						text-align: justify;
						color: #60666F;
						margin-top: 12px;
					}
				}
			}
		}

	}
</style>
